@font-face {
  font-family: 'GT Walsheim Pro';
  src: local('GT Walsheim Pro'),
    url(./GTWalsheimPro-Regular.woff2) format('woff2'),
    url(./GTWalsheimPro-Regular.woff) format('woff');
}

@font-face {
  font-family: 'GT Walsheim Pro';
  font-weight: 300;
  src: url(./GTWalsheimPro-Light.woff2) format('woff2'),
    url(./GTWalsheimPro-Light.woff) format('woff');
}

@font-face {
  font-family: 'GT Walsheim Pro';
  font-weight: 500;
  src: url(./GTWalsheimPro-Medium.woff2) format('woff2'),
    url(./GTWalsheimPro-Medium.woff) format('woff');
}

@font-face {
  font-family: 'GT Walsheim Pro';
  font-weight: 700;
  src: url(./GTWalsheimPro-Bold.woff2) format('woff2'),
    url(./GTWalsheimPro-Bold.woff) format('woff');
}

@font-face {
  font-family: 'GT Walsheim Pro';
  font-weight: 800;
  src: url(./GTWalsheimPro-UltraBold.woff2) format('woff2'),
    url(./GTWalsheimPro-UltraBold.woff) format('woff');
}
